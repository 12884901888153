import axios from "axios";
// let token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1OTMxMGUyOS0wOTgwLTQxODItOTY0Ny01ZmU3YWM4Mzk0ZDYiLCJ2YWxpZCI6IjEiLCJuYW1lIjoiYWRtaW5AZ21haWwuY29tIiwicGFzc3dvcmQiOiIxMjM0IiwiZXhwIjoxNjkxOTk0Mjk4LCJpc3MiOiJodHRwOi8vbXlzaXRlLmNvbSIsImF1ZCI6Imh0dHA6Ly9teXNpdGUuY29tIn0.rGihCeu5UVjnpHOappESWj68DVZrT2kdMHMYvbOtG1s";
// let http = axios.default.create({
//   baseURL: "http://formbuilerapp.ezeefiles.in/api/Form_Builder",
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });
let token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1ZmVkOTI5YS1kYjNiLTQ4NTgtYjRiNC04NTc4NGQ2MTdmNTkiLCJ2YWxpZCI6IjEiLCJuYW1lIjoiYWRtaW5AbWVkd2F5LmNvbSIsInBhc3N3b3JkIjoiTWVkd2F5QDEyMzQiLCJleHAiOjE3MDEzMzQ2OTksImlzcyI6Imh0dHA6Ly9teXNpdGUuY29tIiwiYXVkIjoiaHR0cDovL215c2l0ZS5jb20ifQ.lrXS_q1Dk4VDhgFTwvJ22SsbPwm6d8dbPf9rvin9CtY";
let http = axios.default.create({
  baseURL: "https://demomedwayapi.convenientcare.life/api/Form_Builder",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
class FormBuilderService {
  formMaster(data) {
    return http.post("/insert_update_deleteFormMaster", data);
  }

  updatePosition(data) {
    return http.post("/insert_FormField", data);
  }

  formStep(data) {
    return http.post("/insert_update_deleteFormStep", data);
  }
  formCondition(data) {
    return http.post("/insert_update_deleteFormFormula", data);
  }
  formField(data) {
    return http.post("/insert_update_deleteFormField", data);
  }
  formPublish(data) {
    return http.post("/insert_update_deleteFormversion", data);
  }
  submitForm(data) {
    return http.post("/insert_update_deleteFormmasterdata", data);
  }
  getEditForm(params) {
    return http.get("/FormMasterData", { params });
  }
}

export default new FormBuilderService();
