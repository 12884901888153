<template>
  <n-card content-style="padding: 0px;" class="mb-1">
    <div
      slot:header
      :style="isCollapsible ? 'cursor: pointer' : ''"
      @click="openSection()"
      class="bg-primary p-3 text-white d-flex justify-space-between items-center"
    >
      <span :style="labelStyle">
        <b>
          {{ label }}
        </b>
      </span>
      <n-icon size="large" class="text-white" v-if="isCollapsible">
        <ChevronUpOutline v-if="isOpen"></ChevronUpOutline>
        <ChevronDownOutline v-else></ChevronDownOutline>
      </n-icon>
    </div>
    <transition name="slide-up">
      <div class="p-3" v-show="isOpen">
        <f-preview
          v-for="field in fieldList"
          :field="field"
          :parentPath="getParentPath"
          v-bind:key="field.Field_Id"
        />
      </div>
    </transition>
  </n-card>
</template>

<script>
import { ChevronDownOutline, ChevronUpOutline } from "@vicons/ionicons5";
import { FormField } from "../../assets/model/form.master.model";
import formMixins from "../../mixins/form.mixins";
import { getSettings } from "../../assets/js/common.helper";
export default {
  props: {
    label: { type: String, default: "" },
    field: {},
  },
  data() {
    return {
      isOpen: true,
    };
  },
  mixins: [formMixins],
  computed: {
    isCollapsible() {
      let settings = getSettings({ ...this.field });
      return settings.is_collapsible == true;
    },
    parent() {
      return new FormField(this.field).Field_Id;
    },
    fieldList() {
      return [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
    },
  },
  methods: {
    openSection() {
      if (!this.isCollapsible) return;
      this.isOpen = !this.isOpen;
    },
  },
  components: { ChevronUpOutline, ChevronDownOutline },
};
</script>

<style>
/* .smooth-enter-active,
.smooth-leave-active {
  transition: max-height 0.5s;
}
.smooth-enter,
.smooth-leave-to {
  max-height: 0;
} */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>