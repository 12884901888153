<template>
  <n-space justify="end">
    <fb-add-step v-if="isStepForm"></fb-add-step>
    <fb-condition type="button"></fb-condition>
    <info-button @click="$router.push(`/form-preview/${id}`)"
      >Preview</info-button
    >
    <primary-button @click="publish()">Publish</primary-button>
  </n-space>
</template>

<script>
import InfoButton from "../ui/buttons/InfoButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import FbAddStep from "./FbAddStep.vue";
import FbCondition from "./FbCondition.vue";
import {
  FormPublish,
  FormPublishMaster,
} from "../../assets/model/form.publish.model.js";
import { FormMaster } from "../../assets/model/form.master.model";
import formBuilderService from "../../assets/services/form.builder.service";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    PrimaryButton,
    InfoButton,
    FbAddStep,
    FbCondition,
  },
  data() {
    return {};
  },
  methods: {
    async publish() {
      const vm = this;
      let { formData, formSteps, formConditions, formFields } = vm;
      let form = new FormPublishMaster({
        ...formData,
        steps: formSteps,
        fields: formFields,
        conditions: formConditions,
      });
      let doc = new FormPublish({
        Dml_Indicator: "IS",
        Form_id: new FormMaster(formData).Formid,
        File_Location: JSON.stringify(form),
      });
      vm.$confirm({
        message: "Are you sure, Do you want to publish this form?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let { status, msg } = (await formBuilderService.formPublish(doc))
              .data;
            vm.$loader.hide();
            if (!status) {
              vm.$alert(msg);
              return;
            }
          } catch (error) {
            vm.handleError(error);
          }
        },
      });
    },
  },
};
</script>

<style>
</style>