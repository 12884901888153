<template>
  <div>
    <icon-button v-if="fieldId" size="small" @click="dialog = true">
      <settings-sharp></settings-sharp>
    </icon-button>
    <n-modal
      v-model:show="dialog"
      :title="title"
      preset="card"
      :style="bodyStyle"
      :bordered="false"
      :closeOnEsc="false"
      :maskClosable="false"
      :closable="false"
    >
      <form-wrapper @onSubmit="submit()" :rules="rules" :model="form">
        <n-grid :x-gap="12" :cols="2">
          <n-gi>
            <input-field
              path="Form_Label"
              v-model:value="form.Form_Label"
              label="Field Label"
            ></input-field>
          </n-gi>
          <n-gi>
            <input-field
              v-model:value="form.Form_settings.label_font"
              label="Field Label Font Size"
              type="number"
            ></input-field>
          </n-gi>
          <n-gi>
            <color-picker
              v-model:value="form.Form_settings.label_color"
              label="Field Label Color"
            ></color-picker>
          </n-gi>
          <n-gi v-if="isTextField">
            <select-field
              path="Field_Type"
              :options="fieldTypes"
              v-model:value="form.Field_Type"
              label="Field Type"
            ></select-field>
          </n-gi>
          <n-gi v-if="isCharacterField">
            <select-field
              :options="characterTypes"
              clearable
              v-model:value="form.Form_settings.character"
              label="Charactor Type"
            ></select-field>
          </n-gi>
          <n-gi>
            <input-field
              path="Field_Position"
              type="number"
              v-model:value="form.Field_Position"
              label="Field Position"
            ></input-field>
          </n-gi>
          <n-gi v-if="isGridField">
            <input-field
              path="Form_settings.column"
              v-model:value="form.Form_settings.column"
              label="No of columns"
              type="number"
            ></input-field>
          </n-gi>

          <n-gi v-if="isPlaceholder">
            <input-field
              path="Form_settings.placeholder"
              v-model:value="form.Form_settings.placeholder"
              label="Placeholder"
            ></input-field>
          </n-gi>
          <n-gi v-if="isTextField">
            <input-field
              path="Form_settings.default_value"
              :type="form.Field_Type"
              v-model:value="form.Form_settings.default_value"
              label="Default Value"
            ></input-field>
          </n-gi>
          <n-gi v-if="isDateField">
            <date-field
              path="Form_settings.default_value"
              :type="form.Field_Type"
              v-model:value="form.Form_settings.default_value"
              label="Default Value"
            ></date-field>
          </n-gi>
          <n-gi v-if="isTimeField">
            <time-field
              path="Form_settings.default_value"
              :type="form.Field_Type"
              v-model:value="form.Form_settings.default_value"
              label="Default Value"
            ></time-field>
          </n-gi>

          <n-gi v-if="isSectionField">
            <radio-group-field
              :options="yesNo"
              path="Form_settings.is_collapsible"
              v-model:value="form.Form_settings.is_collapsible"
              label="Is section collapsible?"
            >
            </radio-group-field>
          </n-gi>
          <n-gi>
            <radio-group-field
              :options="yesNo"
              path="Form_settings.is_visible"
              v-model:value="form.Form_settings.is_visible"
              label="Is visible field?"
            >
            </radio-group-field>
          </n-gi>
          <n-gi v-if="isTextField || isOptionField">
            <input-field
              path="Form_settings.unit"
              v-model:value="form.Form_settings.unit"
              label="Unit"
            ></input-field>
          </n-gi>

          <n-gi v-if="isRequiredField">
            <n-grid :x-gap="12" :cols="2">
              <n-gi>
                <radio-group-field
                  :options="yesNo"
                  path="Form_settings.is_required"
                  v-model:value="form.Form_settings.is_required"
                  label="Is required field?"
                >
                </radio-group-field>
              </n-gi>
              <n-gi v-if="isMultiSelect">
                <radio-group-field
                  :options="yesNo"
                  path="Form_settings.is_multiple"
                  v-model:value="form.Form_settings.is_multiple"
                  label="Is multiple select?"
                >
                </radio-group-field>
              </n-gi>
              <n-gi v-if="isDateField">
                <radio-group-field
                  :options="yesNo"
                  path="Form_settings.is_future"
                  v-model:value="form.Form_settings.is_future"
                  label="Is future date allowed?"
                >
                </radio-group-field>
              </n-gi>
            </n-grid>
          </n-gi>
          <template v-if="isTextField">
            <n-gi>
              <n-grid :x-gap="12" :cols="2">
                <n-gi>
                  <input-field
                    :label="minCharLabel"
                    type="number"
                    path="Form_settings.min_char"
                    v-model:value="form.Form_settings.min_char"
                  ></input-field>
                </n-gi>
                <n-gi>
                  <input-field
                    :label="maxCharLabel"
                    type="number"
                    path="Form_settings.max_char"
                    v-model:value="form.Form_settings.max_char"
                  ></input-field>
                </n-gi>
              </n-grid>
            </n-gi>
          </template>
          <template v-if="isFileField">
            <n-gi>
              <n-grid :x-gap="12" :cols="2">
                <n-gi>
                  <input-field
                    label="Max file size (MB)"
                    type="number"
                    path="Form_settings.max_size"
                    v-model:value="form.Form_settings.max_size"
                  ></input-field>
                </n-gi>
                <n-gi>
                  <input-field
                    label="Max files allowed"
                    type="number"
                    path="Form_settings.max_files"
                    v-model:value="form.Form_settings.max_files"
                  ></input-field>
                </n-gi>
              </n-grid>
            </n-gi>
            <n-gi>
              <select-field
                label="File Type"
                type="number"
                :options="fileTypes"
                multiple
                path="Form_settings.file_type"
                v-model:value="form.Form_settings.file_type"
              ></select-field>
            </n-gi>
          </template>
          <n-gi v-if="isOptionField">
            <select-field
              path="Form_settings.default_value"
              :options="options"
              ref="defaultValue"
              clearable
              v-model:value="form.Form_settings.default_value"
              label="Default Value"
            ></select-field>
          </n-gi>
          <n-gi v-if="isRequiredField">
            <input-field
              path="Form_settings.help_text"
              v-model:value="form.Form_settings.help_text"
              label="Help Text"
            ></input-field>
          </n-gi>
          <n-gi v-if="isRequiredField">
            <input-field
              path="Form_settings.help_text"
              v-model:value="form.Form_settings.reference_key"
              label="Reference Key"
            ></input-field>
          </n-gi>
          <template v-if="isOptionField">
            <n-gi :span="2">
              <radio-group-field
                :options="yesNo"
                path="Form_settings.is_dynamic"
                v-model:value="form.Form_settings.is_dynamic"
                label="Is Dynamic options?"
              >
              </radio-group-field>
            </n-gi>
            <n-gi :span="2">
              <fb-api-config
                :field-id="fieldId"
                v-if="form.Form_settings.is_dynamic"
                v-model="form.Form_settings.api"
              />
              <dynamic-tag-field
                v-else
                @update:value="onOptionUpdate()"
                path="Form_settings.options"
                v-model:value="form.Form_settings.options"
                label="Options"
              />
            </n-gi>
          </template>
          <n-gi :span="2" v-if="isQuestioner">
            <fb-questioner-options v-model="form.Form_settings.options" />
          </n-gi>
          <n-gi :span="2" v-if="isQuestionerResult">
            <fb-formula ref="formula" v-model="form.Form_settings.formula" />
          </n-gi>
        </n-grid>
        <template v-slot:footer>
          <close-button @click="dialog = false"></close-button>
          <primary-button :loading="loading" attr-type="submit"
            >Submit</primary-button
          >
        </template>
      </form-wrapper>
    </n-modal>
  </div>
</template>
  
<script>
import {
  FormField,
  FormMaster,
  FormStep,
} from "../../assets/model/form.master.model";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import rulesConstant from "../../assets/constants/rules.constant";
import CloseButton from "../ui/buttons/CloseButton.vue";
import formBuilderService from "../../assets/services/form.builder.service";
import formMixins from "../../mixins/form.mixins";
import SelectField from "../ui/form/SelectField.vue";
import {
  fieldTypes,
  optionFields,
  fileTypes,
  textFields,
  multiSelectFields,
  characterFields,
  characterTypes,
} from "../../assets/constants/form.builder.constant";
import IconButton from "../ui/buttons/IconButton.vue";
import { SettingsSharp } from "@vicons/ionicons5";
import DynamicTagField from "../ui/form/DynamicTagField.vue";
import RadioGroupField from "../ui/form/RadioGroupField.vue";
import DateField from "../ui/form/DateField.vue";
import TimeField from "../ui/form/TimeField.vue";
import ColorPicker from "../ui/form/ColorPicker.vue";
import FbApiConfig from "./FbApiConfig.vue";
import FbQuestionerOptions from "./FbQuestionerOptions.vue";
import FbFormula from "./FbFormula.vue";
export default {
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormWrapper,
    InputField,
    PrimaryButton,
    CloseButton,
    SelectField,
    IconButton,
    SettingsSharp,
    DynamicTagField,
    RadioGroupField,
    DateField,
    TimeField,
    ColorPicker,
    FbApiConfig,
    FbQuestionerOptions,
    FbFormula,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fileTypes,
      characterTypes,
      yesNo: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      form: {
        Field_Type: "",
        Form_Label: "",
        is_collapsible: false,
        Field_Position: 0,
        Form_settings: {
          api: {},
        },
        Parent: 0,
      },
      bodyStyle: { width: "800px" },
    };
  },
  mixins: [formMixins],
  computed: {
    isSectionField() {
      return this.type == "section";
    },
    minCharLabel() {
      let type = this.form.Field_Type;
      if (type == "currency" || type == "number") return "Min Value";
      return "Min Character";
    },
    maxCharLabel() {
      let type = this.form.Field_Type;
      if (type == "currency" || type == "number") return "Max Value";
      return "Max Character";
    },
    isGridField() {
      return this.type == "grid";
    },
    rules() {
      return {
        Form_Label:
          ["grid", "radio"].indexOf(this.type) == -1
            ? rulesConstant.required
            : {},
        Field_Position: rulesConstant.required,
        Field_Type: rulesConstant.required,
        Form_settings: {
          options: rulesConstant.required,
          is_required: rulesConstant.required,
          is_dynamic: rulesConstant.required,
          is_collapsible: rulesConstant.required,
          column: rulesConstant.required,
          is_future: rulesConstant.required,
          is_multiple: rulesConstant.required,
          is_visible: rulesConstant.required,
          api: {
            method: rulesConstant.required,
            url: rulesConstant.urlRequired,
            name_key: rulesConstant.required,
            value_key: rulesConstant.required,
          },
        },
      };
    },
    options() {
      let options = this.form.Form_settings.options || [];
      return options.map((label) => ({ label, value: label }));
    },
    fieldId() {
      return new FormField(this.field).Field_Id;
    },
    title() {
      return this.fieldId ? "Update Field" : "Add Field";
    },
    fieldTypes() {
      return fieldTypes
        .filter((i) => textFields.indexOf(i.key) != -1)
        .map((i) => ({ ...i, value: i.key }));
    },
    type() {
      return this.form.Field_Type;
    },
    isDateField() {
      return ["date", "datetime"].indexOf(this.type) != -1;
    },
    isTimeField() {
      return ["time"].indexOf(this.type) != -1;
    },
    isPlaceholder() {
      return (
        textFields.indexOf(this.type) != -1 ||
        this.type == "select" ||
        this.isDateField ||
        this.isTimeField
      );
    },
    isTextField() {
      return textFields.indexOf(this.type) != -1;
    },
    isCharacterField() {
      return characterFields.indexOf(this.type) != -1;
    },
    isFileField() {
      return this.type == "file-upload";
    },
    isSignatureField() {
      return this.type == "signature";
    },
    isOptionField() {
      return optionFields.indexOf(this.type) != -1;
    },
    isMultiSelect() {
      return multiSelectFields.indexOf(this.type) != -1;
    },
    isQuestioner() {
      return this.type == "questioner";
    },
    isQuestionerResult() {
      return this.type == "questioner-result";
    },
    isRequiredField() {
      return (
        this.isFileField ||
        this.isOptionField ||
        this.isTextField ||
        this.isSignatureField ||
        this.isDateField ||
        this.isTimeField ||
        this.isQuestioner
      );
    },
  },
  watch: {
    dialog() {
      this.init();
    },
  },
  methods: {
    onOptionUpdate() {
      const vm = this;
      vm.$nextTick(() => {
        let item = vm.options.find(
          (i) => i.value == vm.form.Form_settings.default_value
        );
        if (item == null) vm.$refs.defaultValue.input = "";
      });
    },
    initAddField({ type, parent, position }) {
      console.log(position);
      this.dialog = true;
      this.form = {
        Field_Type: type,
        Form_Label: "",
        Field_Position: position,
        Form_settings: {
          is_required: false,
          is_dynamic: false,
          is_multiple: false,
          is_future: false,
          is_visible: true,
          api: {},
        },
        Parent: parent,
      };
      console.log(this.form);
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let formId = new FormMaster(vm.formData).Formid;
        let doc = new FormField({
          ...vm.form,
          Dml_Indicator: "IS",
          Form_id: formId,
        });
        if (vm.isQuestionerResult && !vm.form.Form_settings.formula) {
          vm.$alert("Please enter the formula");
          return;
        }
        if (vm.isStepForm) {
          doc.Step_id = new FormStep(vm.formStep).Step_id;
        }
        if (vm.fieldId) {
          doc.Field_Id = vm.fieldId;
          doc.Dml_Indicator = "UP";
        }
        let { status, msg } = (await formBuilderService.formField(doc)).data;
        if (!status) {
          vm.loading = false;
          vm.$alert(msg);
          return;
        }
        vm.updatePosition();
        vm.dialog = false;
        if (!vm.fieldId) {
          vm.form = {
            Field_Type: "",
            Form_Label: "",
            Field_Position: 0,
            Form_settings: {},
            Parent: 0,
          };
        }
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async updatePosition() {
      const vm = this;
      console.log(vm.form.Field_Position, vm.field.Field_Position);
      if (vm.fieldId && vm.form.Field_Position != vm.field.Field_Position) {
        // eslint-disable-next-line no-inner-declarations
        function changePosition(arr, fromIndex, toIndex) {
          var element = arr[fromIndex];
          arr.splice(fromIndex, 1);
          arr.splice(toIndex, 0, element);
        }
        let table = [...this.formStepFields]
          .filter((i) => i.Parent == this.field.Parent)
          .map((i) => ({ ...i }));
        let index = table.findIndex((i) => i.Field_Id == this.field.Field_Id);
        changePosition(table, index, vm.form.Field_Position - 1);
        let list = table.map((item, i) => ({
          Dml_Indicator: "UPP",
          Field_Position: i + 1,
          Field_Id: item.Field_Id,
        }));
        await formBuilderService.updatePosition(list);
        await vm.fetchFormFields();
      } else {
        await vm.fetchFormFields();
      }
    },
    init() {
      const vm = this;
      Object.keys(vm.form).forEach((key) => {
        if (vm.field[key] != undefined) vm.form[key] = vm.field[key];
      });
      if (vm.field.Form_settings) {
        try {
          vm.form.Form_settings = JSON.parse(vm.field.Form_settings);
        } catch (error) {
          vm.form.Form_settings = {};
        }
      }
      if (vm.form.Form_settings instanceof Object == false) {
        vm.form.Form_settings = {};
      }
      if (!vm.fieldId) {
        // vm.form.Field_Position = vm.formFields.length + 1;
      }
      if (vm.fieldId && vm.isQuestionerResult) {
        vm.$nextTick(() => {
          if (vm.$refs.formula) {
            vm.$refs.formula.init(vm.form.Form_settings.formula);
          }
        });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
  
<style></style>