<template>
  <n-card class="flex-grow-1 pa-4" flat>
    <div class="flex-1" :style="labelStyle">
      {{ field.Form_Label }}
    </div>
    {{ html }}
    <vue-editor
      class="mt-1"
      ref="editor"
      :use-save-button="true"
      v-model="html"
      :editor-toolbar="customToolbar"
    ></vue-editor>
  </n-card>
</template>

<script>
import { VueEditor, Quill } from "vue3-editor";
const sizeStyle = Quill.import("attributors/style/size");
sizeStyle.whitelist = [
  "6px",
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "30px",
  "32px",
  "36px",
];
Quill.register(sizeStyle, true);
export default {
  props: {
    label: { type: String, default: "" },
    field: {},
    value: { type: String, default: "" },
  },
  components: { VueEditor },
  data() {
    return {
      html: "",
      customToolbar: [
        [
          // { header: [false, 1, 2, 3, 4, 5, 6] },
          {
            size: [
              false,
              "6px",
              "8px",
              "10px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "24px",
              "30px",
              "32px",
              "36px",
            ],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "video"],
        ["clean"],
      ],
    };
  },
  watch: {
    html(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.html = this.value || "";
  },
};
</script>

<style>
</style>