<template>
  <n-button strong v-bind="$attrs" ghost type="primary">
    <template #icon>
      <n-icon :component="icon"> </n-icon>
    </template>
    <slot></slot>
  </n-button>
</template>
<script>
import { Add } from "@vicons/ionicons5";

export default {
  inheritAttrs: false,
  data() {
    return {
      icon: Add,
    };
  },
};
</script>

<style></style>
